<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <!-- 搜索 -->
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :flex="'6'"
          @search="searchFn('search')"
        >
          <search-item style="width: 20%">
            <el-input
              size="small"
              v-model="searchParams.orderCode"
              placeholder="订单号"
              clearable
            />
          </search-item>
          <search-item style="width: 20%">
            <el-input
              size="small"
              v-model="searchParams.agencyName"
              placeholder="机构名称"
              clearable
            />
          </search-item>
          <search-item style="width: 10%">
            <el-select
              v-model="searchParams.assignStatus"
              placeholder="分配状态"
              clearable
            >
              <el-option
                v-for="item in operateStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item style="width: 10%">
            <el-select
              v-model="searchParams.staffIdList"
              placeholder="运营人员"
              clearable
            >
              <el-option
                v-for="item in operationList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item style="width: 10%">
            <el-select
              v-model="searchParams.orderStatus"
              placeholder="订单类型"
              clearable
            >
              <el-option
                v-for="item in orderTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </search-item>
          <!-- 高级搜索 -->
        </table-search>
      </div>
      <div class="bd-content">
        <!-- 操作按钮 -->
        <div class="table-opt">
          <el-button
            type="primary"
            class="el-icon-circle-plus-outline"
            @click="goDetail('add')"
          >
            新 增
          </el-button>
          <el-button @click="allot()"> 批量分配 </el-button>
        </div>
        <pageTable
          ref="table"
          row-key="orderId"
          :columns="columnList"
          :params="searchParams"
          :api-function="this.$api.operationOrder.getOrderList"
          @selectAll="selectAll"
          @select="select"
        >
          <template slot="agencyName" slot-scope="scope">
            <div class="data-operate">
              <a
                style="color: #2281ff"
                href="javascript:;"
                @click="webSite(scope.row)"
              >
                {{ scope.row.agencyName }}
              </a>
            </div>
          </template>
          <template slot="siteUrl" slot-scope="scope">
            <a @click="openWin(scope.row.siteUrl)" style="cursor: pointer">{{
              scope.row.siteUrl
            }}</a>
          </template>
          <template slot="assignStatusDesc" slot-scope="scope">
            <div
              :class="
                scope.row.assignStatusDesc === '未分配'
                  ? 'assignClass1'
                  : 'assignClass2'
              "
            >
              {{ scope.row.assignStatusDesc }}
            </div>
          </template>
          <template slot="orderStatus" slot-scope="scope">
            <div
              :class="
                scope.row.orderStatus === '1000'
                  ? 'orderClass1'
                  : scope.row.orderStatus === '2000'
                  ? 'orderClass3'
                  : 'orderClass4'
              "
            >
              <span
                v-if="
                  scope.row.orderStatus === '1000'
                "
                >初始化</span
              >
              <span v-if="scope.row.orderStatus === '2000'">服务中</span>
              <span v-if="scope.row.orderStatus === '3000'">已结束</span>
            </div>
          </template>
          <template slot="options" slot-scope="scope">
            <div class="data-operate">
              <span
                class="op-blue"
                @click="webSite(scope.row)"
                style="margin-right: 10px"
                >详 情</span
              >

              <span
                class="op-blue"
                @click="goDetail('edit', scope.row)"
                style="margin-right: 10px"
                >编 辑</span
              >
              <span class="op-red" @click="open(scope.row)">停 止</span>
              <span
                class="op-blue"
                @click="del(scope.row)"
                style="margin-right: 10px"
                >删 除</span
              >
            </div>
          </template>
        </pageTable>
      </div>
    </div>
    <more-edit
      v-if="dialogMoreVisible"
      :dialogVisible.sync="dialogMoreVisible"
      :contentData="selectData"
      @update="searchFn()"
    />
  </div>
</template>

<script>
import moreEdit from '@/views/orderManage/components/moreEdit.vue';

export default {
  name: 'orderList',
  components: {
    moreEdit,
  },
  data() {
    return {
      operateStatus: [
        { label: '已分配', value: '1' },
        { label: '未分配', value: '0' },
      ],
      operationList: [],
      selectData: [],
      contentData: {}, // 分配时回显数据
      searchParams: {
        //	订单号
        orderCode: '',
        //	机构名称
        agencyName: '',
        //	运营人员
        staffIdList: '',
        // 订单状态：1000-初始化，2000-服务中，3000-已结束
        orderStatus: '',
        // 分配状态：0-未分配，1-已分配
        assignStatus: '',
      },
      orderTypeList: [
        { label: '初始化', value: '1000' },
        { label: '服务中', value: '2000' },
        { label: '已结束', value: '3000' },
      ],
      columnList: [
        {
          type: 'selection',
        },
        {
          label: '订单号',
          prop: 'orderCode',
        },
        {
          label: '机构名称',
          prop: 'agencyName',
          show: 'template',
        },
        {
          label: '运营人员',
          prop: 'staffIdsDesc',
        },
        {
          label: '网址',
          prop: 'siteUrl',
          show: 'template',
        },
        {
          label: '订单开始时间',
          prop: 'orderStartTime',
        },
        {
          label: '订单结束时间',
          prop: 'orderEndTime',
        },
        {
          label: '分配状态',
          show: 'template',
          width: 90,
          prop: 'assignStatusDesc',
        },
        {
          label: '订单状态',
          show: 'template',
          width: 110,
          prop: 'orderStatus',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: 175,
        },
      ],
      dialogVisible: false,
      dialogMoreVisible: false,
      dialogType: '',
      tableRef: '',
    };
  },
  watch: {},
  activated() {
    this.searchFn('edit');
  },
  mounted() {
    this.tableRef = this.$refs.table;
    this.getUserList();
  },
  methods: {
    // 批量分配
    allot() {
      if (this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      } else {
        this.dialogMoreVisible = true;
      }
    },
    // 运营用户-运营管理-查询所有运营用户
    getUserList() {
      let obj = {
        roleKeys: 'operations_user,operations_admin',
      };
      this.$api.operationOrder.getUserList(obj).then((res) => {
        this.operationList = res.data.list;
      });
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    searchFn(status) {
      this.tableRef.getPageDataOfAction(status);
    },
    openWin(url) {
      if (url) {
        window.open(url);
      }
    },
    //停止
    open(data) {
      this.$msg.confirm(`确认停止订单？`).then(() => {
        this.$api.operationOrder
          .updateOrderStatus({
            orderCodes: [data.orderCode],
            orderStatus: '3000',
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.searchFn('edit');
            }
          });
      });
    },
    del(params) {
      let wordErrorIds = params.orderCode;
      this.$msg.confirm('确认删除错词？').then(() => {
        this.$api.operationOrder
          .deleteOrder({ ids: wordErrorIds })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.$refs.table.getPageDataParams();
            }
            this.selectData = [];
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      });
    },
    // 栏目管理
    goDetail(type, data) {
      var metaTitle = '';
      switch (type) {
        case 'add':
          metaTitle = '新增订单';
          break;
        case 'detail':
          metaTitle = '订单详情';
          break;

        case 'edit':
          metaTitle = '编辑订单';
          break;
      }
      var query = {
        orderCode: data ? data.orderCode : '',
        type,
        metaTitle,
      };
      this.$router.push({
        path: '/newAddOrder',
        query,
      });
    },
    // 网站设置
    webSite(row) {
      var query = {
        orderCode: row.orderCode,
      };
      this.$router.push({
        path: '/contentWatchList',
        query,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.assignClass1 {
  width: 100%;
  height: 100%;
  border: 1px solid #508cff;
  background: #e8f8f5;
  color: #508cff;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}
.assignClass2 {
  width: 100%;
  height: 100%;
  border: 1px solid #1ebfa0;
  background: #e8f8f5;
  color: #1ebfa0;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}
.orderClass1 {
  width: 100%;
  height: 100%;
  border: 1px solid #e3e6ea;
  background: #1ebfa0;
  color: #fff;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}
.orderClass3 {
  width: 100%;
  height: 100%;
  border: 1px solid #e3e6ea;
  background: #508cff;
  color: #fff;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}
.orderClass4 {
  width: 100%;
  height: 100%;
  border: 1px solid #e3e6ea;
  background: rgb(210, 210, 230);
  color: #fff;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}
</style>
