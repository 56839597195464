<template>
  <el-dialog
    :title="'批量分配'"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="40%"
  >
    <div class="dialog-content">
      <span class="dialog-tit">订单列表</span>
      <table border="1" class="colsapn-table">
        <tr v-for="(val, ind) in resultData" :key="ind">
          <th class="colsapn-th">
            <span>{{ ind + 1 }}</span>
          </th>
          <th class="actived">{{ val.agencyName || '-' }}</th>
          <th class="del" @click="handleDel(val)">
            <i class="el-icon-close"></i>&nbsp; 删除
          </th>
        </tr>
      </table>
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="运营人员:" prop="operationUserName">
          <el-select
            v-model="form.operationUserName"
            placeholder=""
            @change="handleUser"
            clearable
          >
            <el-option
              v-for="item in operationList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>
  <script>
export default {
  name: 'editContent',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    contentData: {
      type: Array,
      default: () => [],
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    return {
      introduction: [],
      levalList: [],
      roleList: [],
      questionType: [],
      questionTypeName: '',
      operationList: [],
      form: {
        operationUserName: '',
      },
      userIds: '',
      rules: {
        operationUserName: [{ required: true, message: '请选择运维人员' }],
      },
      resultData: [],
    };
  },
  mounted() {
    if (this.contentData.length) {
      this.resultData = this.contentData;
    }
    this.operationFindAllOperationUser();
  },
  methods: {
    // 运营用户-运营管理-查询所有运营用户
    operationFindAllOperationUser() {
      let obj = {
        roleKeys: 'operations_user,operations_admin',
      };
      this.$api.operationOrder.getUserList(obj).then((res) => {
        this.operationList = res.data.list;
      });
    },
    fileDetail(url) {
      window.open(url, '_blank');
    },
    // 删除单条数据
    handleDel(val) {
      if (this.resultData.length > 1) {
        this.$msg.confirm('确定要删除该条数据吗?').then(() => {
          this.resultData = this.resultData.filter(
            (item) => item.orderCode !== val.orderCode
          );
        });
      } else {
        this.$message.warning('请至少保留一条数据!');
      }
    },
    // 运维人员选择
    handleUser(val) {
      this.userIds = val;
    },
    eidt() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var obj = {};
          var params = [];
          if (this.resultData.length) {
            this.resultData.forEach((item) => {
              params.push(item.orderCode);
            });
            obj.orderCodes = params;
            obj.userIds = [this.userIds];
          }
          this.$api.operationOrder.addDistribution(obj).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.$emit('update:dialogVisible', false);
              this.$emit('update');
            }
          });
        }
      });
    },
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.eidt();
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.dialog-content {
  width: 90%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
}
/****合并单元格样式开始****/
.colsapn-table {
  width: 100%;
  height: 100%;
  color: #333;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  tr .rowspan-item {
    background-color: #f2f2f2;
  }
  tr th,
  tr td {
    overflow: hidden;
    word-break: break-all;
    word-wrap: break-word;
    min-height: 30px;
    padding: 5px;
    font-weight: bold;
    color: #333;
    font-weight: normal !important;
  }
  tr td {
    width: 20%;
  }
  .colsapn-th {
    background: #f6f6f6;
    width: 50px;
  }
}
.colsapn-table-thead {
  background: #cbe7f3;
}
.actived {
  cursor: pointer;
}
.dialog-tit {
  display: inline-block;
  margin-bottom: 8px;
}
.del {
  color: red !important;
  width: 15%;
  cursor: pointer;
}
.rowspan-item-l {
  background: #f5f5f5;
}
</style>
   